<template>
  <a-card>
    <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="关联品牌" required>
        <a-button type="primary" @click="brandVisible=true">添加品牌商</a-button>
        <a-table class="m-t-20" :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data'>
          <template v-slot:action="{ index }">
            <a @click="del(index)" href="javascript:;">删除</a>
          </template>
        </a-table>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 16, offset: 2 }">
        <a-button type="primary" @click="comfirmCode">保存</a-button>
      </a-form-item>
    </a-form>
  </a-card>
  <!-- 品牌商弹窗 -->
  <a-modal v-model:visible="brandVisible" title="品牌商" :width='1200' destroyOnClose centered :footer="null">
    <selectBrand v-on:select="brandSelect"  :checkout="true" :selectList="data" />
  </a-modal>
</template>
<script>
import { ref, inject } from "vue";
import { message } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import { createDeviceCode } from "@/api/pda.js";
export default {
  setup() {
    // 列表数据和列头配置
    const data = ref([]);
    let columns = [
      {
        title: "品牌商编号",
        dataIndex: "brand_no",
        key: "brand_no",
      },
      {
        title: "公司名称",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "品牌商账号",
        dataIndex: "account",
        key: "account",
      },
      {
        title: "操作",
        key: "action",
        slots: { customRender: "action" },
      },
    ];
    const $router = inject("$router");
    const _lodash = inject("_lodash");
    // 选中品牌商
    const brandVisible = ref(false);
    const brandSelect = (e) => {
      console.log(e);
      data.value = e;
      brandVisible.value = false;
    };
    const del = (index) =>{
      data.value.splice(index, 1);
    }
    // 确认创建
    const comfirmCode = async () => {
      if (!data.value.length) {
        message.error("请选择品牌商");
        return;
      }
      let result = await createDeviceCode({brand_id:_lodash.map(data.value, "id")}) .then((res) => res.data) .catch((error) => error);
      if ($iscode(result, true)) {
        setTimeout(() => {
          $router.go(-1);
        }, 1000);
      }
    };
    return {
      data,
      columns,
      brandSelect,
      brandVisible,
      comfirmCode,
      del
    };
  },
};
</script>